import React from "react";
import "../css/who-we-are.css";

const WhoWeAre = () => {
  return (
    <section className="who-we-are-section">
      <h1>Who We Are 💡</h1>
      <p>
        At Raza Webs Solution, we are passionate about creating websites that
        are more than just digital spaces – they are powerful tools for growth.
        Our mission is to deliver innovative, user-friendly, and visually
        stunning web solutions at competitive prices without ever compromising
        on quality. We believe in turning big dreams into reality, empowering
        businesses and individuals to thrive online. With a strong focus on
        excellence and customer satisfaction, we ensure every project reflects
        our commitment to innovation, reliability, and success. Let us bring
        your vision to life with our expertise and dedication.
      </p>
    </section>
  );
};

export default WhoWeAre;
